export const Colors = {
    LOGINRED: "#990C0C",
    LOGINYELLOW :'#F7D552',
    DEFAULTBG :'#EDEDED',
    INACTIVE :"#AB1515",
    CRITICAL :"#FF0000",
    ACTIVE :"#45AB15",
    WHITE :"#FFF",
    SUBICONBG :'#D4D4D4',
    CIRCULERPINK :'#990C0C24',
    THEMEPINK :'#FFF2F2',
    LOGINTEXT :'#F1BBBB'

 
 }

 // statusClasses.js

export const STATUS_CLASSES = {
    active: {
        badgeColor: 'success',
        textColor: 'text-green-dark'
    },
    inactive: {
        badgeColor: 'warning',
        textColor: 'text-orange-dark'
    },
    blocked: {
        badgeColor: 'danger',
        textColor: 'text-red-dark'
    },
    default: {
        badgeColor: 'bg-gray-400',
        textColor: 'text-gray-600'
    }
};
