export const customerMasterVariable = {
    customerName : 'customerName',
    customerCode : 'customerCode',
    customerGroup : 'customerGroup',
    customerEmail : 'customerEmail',
    customerVisibility : 'customerVisibility',
    customerType:'customerType',
    customerAddress1 : 'customerAddress1',
    customerAddress2 : 'customerAddress2',
    customerLandmark : 'customerLandmark',
    customerCity : 'customerCity',
    customerState : 'customerState',
    customerPostCode : 'customerPostCode',
    customerCountry : 'customerCountry',
    customerContact : 'customerContact',
    customerGst : 'customerGst',
    customerPan : 'customerPan',
    customerStatus : 'customerStatus',
    customerVat : 'customerVat',
    customerTan : 'customerTan',
}