
export const readerReplacementData = [
    { id : 0, index : 1, readerType : 'Fixed', readerPlacement : 'Inventory In/Out', readerInfo : '246810121416 | Building 01' },
    { id : 1, index : 2, readerType : 'Fixed', readerPlacement : 'Inventory In', readerInfo : '246810121416 | Building 05' },
    { id : 2, index : 3, readerType : 'Handheld', readerPlacement : 'Inventory In/Out', readerInfo : '246810121416 | Building 12' },
    { id : 3, index : 4, readerType : 'Handheld', readerPlacement : 'Inventory In/Out', readerInfo : '246810121416 | Building 12' },
    { id : 4, index : 5, readerType : 'Handheld', readerPlacement : 'Inventory In/Out', readerInfo : '246810121416 | Building 12' },
    { id : 5, index : 6, readerType : 'Handheld', readerPlacement : 'Inventory In/Out', readerInfo : '246810121416 | Building 12' },



]