export default function LoginPasswordIcon({
    strokeWidth,
    ...props
  }) {
    return (
        <svg
        id="fi_1000999"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{height:25}}
        xmlSpace="preserve"
        {...props}
      >
        <g>
          <g>
            <g>
              <circle fill="#AE2026" cx={277.937} cy={346} r={20} />
              <path fill="#AE2026" d="M344.937,471.999h-249c-22.056,0-40-17.944-40-40v-164c0-22.056,17.944-40,40-40h288c4.554,0,8.167,0.402,11.047,1.227                                 c10.618,3.042,21.692-3.1,24.735-13.718c3.042-10.618-3.1-21.692-13.718-24.735c-6.513-1.866-13.73-2.773-22.064-2.773H359.9                                 v-70.534C359.9,52.695,306.068,0,239.9,0s-120,52.695-120,117.466V188H95.937c-44.112,0-80,35.888-80,80v164                                 c0,44.112,35.888,80,80,80h249c11.046,0,20-8.954,20-20C364.937,480.954,355.983,472,344.937,471.999z M159.9,117.466                                 c0-42.715,35.888-77.466,80-77.466s80,34.751,80,77.466V188h-160V117.466z" />
              <circle fill="#AE2026" cx={202.937} cy={346} r={20} />
              <path fill="#AE2026" d="M496.046,331.21c-0.438-38.789-32.13-70.21-71.021-70.21C385.863,261,354,292.862,354,332.025c0,11.046,8.954,20,20,20                                 c11.046,0,20-8.954,20-20C394,314.918,407.918,301,425.026,301c17.108,0,31.025,13.918,31.025,31.025                                 c0,0.182,0.002,0.363,0.007,0.543c-0.206,12.247-7.563,23.211-18.864,28.035c-19.541,8.345-32.168,27.618-32.168,49.101V427                                 c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20v-17.296c0-5.438,3.092-10.271,7.875-12.313                                 c26.227-11.196,43.169-36.855,43.162-65.37C496.063,331.749,496.057,331.479,496.046,331.21z" />
              <circle fill="#AE2026" cx={424.937} cy={492} r={20} />
              <circle fill="#AE2026" cx={127.937} cy={346} r={20} />
            </g>
          </g>
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    );
  }
  