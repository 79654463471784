export const TagMasterVariable = {
    identification : 'identification',
    typeName : 'typeName',
    typeId : 'typeId',
    locationName : 'locationName',
    locationId : 'locationId',
    tagNo : 'tagNo',
    tagUsedForName : 'tagUsedForName',
    tagUsedForId : 'tagUsedForId',
    status : 'status',
    
}