
export const userMasterVariable = {
    firstName : 'firstName',
    lastName : 'lastName',
    username : 'username',
    gender : 'gender',
    roleName : 'roleName',
    roleId : 'roleId',
    contact : 'contact',
    address : 'address',
    email : 'email',
    employeeId : 'employeeId',
}