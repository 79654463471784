export default function WebReceivingIcon({
    strokeWidth,
    ...props
  }) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width={11}
        height={11}
        id="fi_4536732"
        {...props}
      >
        <g id="hand_receive_package" data-name="hand receive package">
          <path d="M44,26V6a1,1,0,0,0-1-1H15a1,1,0,0,0-1,1V22.34c-1.54-.1-1.89,0-6,.53V22a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1V39a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1V38c3.54,0,1.69-.49,11.63,4a11.27,11.27,0,0,0,5.09,1c3.5,0,3.65-.52,21.11-9.44A4,4,0,0,0,44,26ZM32,7v3.38c-3.62-1.81-2.39-1.8-6,0V7ZM16,7h8v5a1,1,0,0,0,1.45.89L29,11.12c3.77,1.88,3.89,2.12,4.53,1.73S34,12.38,34,7h8V26.53L31,32.15a4.09,4.09,0,0,0-.23-1.5C30,28.56,29.42,28.71,18,23.33a11.47,11.47,0,0,0-2-.68ZM6,38H2V23H6Zm38.92-6.22C27.42,40.72,27.56,41,24.72,41a9.32,9.32,0,0,1-4.26-.8C10.34,35.61,12,36,8,36V24.88c8.22-1,5.44-1.47,19.85,5.3a2,2,0,0,1,.95,2.68,2,2,0,0,1-2.65,1L18.26,30.1a1,1,0,0,0-.86,1.8l7.89,3.71a3.92,3.92,0,0,0,3.93-.3l13.86-7.09a2,2,0,1,1,1.84,3.56Z" />
          <path d="M35,25h4a1,1,0,0,0,0-2H35A1,1,0,0,0,35,25Z" />
        </g>
      </svg>
    );
  }
  