export default function WeighingScaleIcon({
    strokeWidth,
    ...props
  }) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11}
        height={11}
        viewBox="0 0 128 128"
        id="fi_2929016"
        {...props}
      >
        <g>
          <path d="M103.559,30.916a19.907,19.907,0,0,0,19.884-19.885V6.307a1.749,1.749,0,0,0-1.75-1.75H6.307a1.749,1.749,0,0,0-1.75,1.75v4.724A19.907,19.907,0,0,0,24.441,30.916H54v11.32H28.376A14.506,14.506,0,0,0,14.018,54.407L4.58,111.256a1.75,1.75,0,0,0,1.727,2.036h5.945v8.4a1.749,1.749,0,0,0,1.75,1.75H114a1.749,1.749,0,0,0,1.75-1.75v-8.4h5.945a1.75,1.75,0,0,0,1.727-2.036l-9.438-56.849A14.506,14.506,0,0,0,99.624,42.236H74.005V30.916ZM8.057,11.031V8.057H119.943v2.974a16.4,16.4,0,0,1-16.384,16.385H24.441A16.4,16.4,0,0,1,8.057,11.031ZM112.248,119.943h-96.5v-6.651h96.5ZM110.529,54.98l9.1,54.812H8.371l9.1-54.812a11.017,11.017,0,0,1,10.905-9.244H99.624A11.017,11.017,0,0,1,110.529,54.98ZM70.505,42.236H57.5V30.916h13.01Z" />
          <path d="M38.314,77.764A25.686,25.686,0,1,0,64,52.079,25.715,25.715,0,0,0,38.314,77.764Zm47.8-1.75H83.182a1.75,1.75,0,0,0,0,3.5H86.11a22.211,22.211,0,0,1-20.36,20.36V96.946a1.75,1.75,0,0,0-3.5,0v2.928a22.211,22.211,0,0,1-20.36-20.36h2.928a1.75,1.75,0,0,0,0-3.5H41.89a22.213,22.213,0,0,1,20.36-20.36v2.928a1.75,1.75,0,0,0,3.5,0V55.654A22.213,22.213,0,0,1,86.11,76.014Z" />
          <path d="M66.288,73A5.252,5.252,0,0,0,64,72.462a5.3,5.3,0,1,0,5.3,5.3,5.233,5.233,0,0,0-.54-2.287l6.893-6.894a1.749,1.749,0,0,0-2.474-2.474ZM64,79.566a1.8,1.8,0,1,1,1.8-1.8A1.8,1.8,0,0,1,64,79.566Z" />
          <path d="M40.064,12.012h-18.2a1.75,1.75,0,1,0,0,3.5h18.2a1.75,1.75,0,0,0,0-3.5Z" />
          <path d="M52.894,12.012H47.54a1.75,1.75,0,0,0,0,3.5h5.354a1.75,1.75,0,1,0,0-3.5Z" />
        </g>
      </svg>
    );
  }
  