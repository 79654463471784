export const usedByOption = [
    { label: 'brokerType', value: 'brokerType' },
    { label: 'brokerSecureConnectionType', value: 'brokerSecureConnectionType' },
    { label: 'brokerCertificate', value: 'brokerCertificate' },
    { label: 'certificateType', value: 'certificateType' },
    { label: 'keyUsage', value: 'keyUsage' },
    { label: 'extendedKeyUsage', value: 'extendedKeyUsage' },
    { label: 'defaultData', value: 'defaultData' },
    { label: 'status', value: 'status' },
    { label: 'gender', value: 'gender' },







];

// extendedKeyUsage