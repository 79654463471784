export default function ReaderReplacementMasterIcon({
    strokeWidth,
    ...props
  }) {
    return (
        <svg
        height={512}
        viewBox="0 0 512 512"
        width={512}
        xmlns="http://www.w3.org/2000/svg"
        id="fi_10009006"
        {...props}
        style={{height:11,width:11}}
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="_02.arrows_retweet" data-name="02.arrows_retweet">
            <path
              id="background"
              d="m256 31a225.07 225.07 0 0 1 87.57 432.33 225.07 225.07 0 0 1 -175.14-414.66 223.45 223.45 0 0 1 87.57-17.67m0-31c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256z"
            />
            <path d="m342.85 345.51c-3.6 0-7.1-1.74-10.71-5.31-11.22-11.11-24.45-24.26-37.53-37.55-4.08-4.16-5.7-9.51-4.31-14.31 1.32-4.61 5.09-8 10.32-9.2a12.15 12.15 0 0 1 2.78-.34c4.59 0 8 2.78 10.4 5.21 4.48 4.46 9 9 13.71 13.8l2 2v-10.55c0-20.53 0-41.76 0-62.64 0-13-5.87-18.83-19-18.91-7.53 0-15.65-.06-24.83-.06-9.82 0-19.81 0-29.47 0h-2.67c-4 0-9.65-.71-12.79-6.86a13.72 13.72 0 0 1 .32-13.41 12.74 12.74 0 0 1 11-6.18l11-.16c8.59-.14 17.48-.28 26.23-.28 10.71 0 19.63.21 28.1.65 22.11 1.14 38.83 19.58 38.89 42.87.05 17.56 0 35.4 0 52.66q0 11.29 0 22.6v.24l2-2.12c4.48-4.63 9.1-9.42 14-13.79 2.66-2.36 7.13-4.74 11.12-4.74a9 9 0 0 1 1 .06 12.64 12.64 0 0 1 10.59 7.66 13.91 13.91 0 0 1 -2.13 13.89 46.78 46.78 0 0 1 -4.44 4.74c-.48.47-1 .94-1.44 1.42l-6.92 6.93c-8.57 8.59-17.43 17.46-26.19 26.15-3.75 3.72-7.36 5.53-11.03 5.53z" />
            <path d="m223.2 331.19c-10.4 0-19.5-.15-27.8-.46a40 40 0 0 1 -28.09-13.1 43.37 43.37 0 0 1 -11.44-29.26c-.09-17.62-.07-35.53-.05-52.84q0-11.46 0-22.9v-.39l-2.66 2.76c-4.47 4.56-9.09 9.28-13.79 13.79a14.93 14.93 0 0 1 -10.27 4.43c-5.61 0-10.33-3.55-12.33-9.26-1.74-5-.52-9.74 3.5-13.81 14.52-14.7 26.76-27 39.27-39.24 2.94-2.87 6.27-4.39 9.63-4.39s6.9 1.6 10 4.61c12.87 12.64 26 25.73 38.92 38.92 5.78 5.88 5.92 13.82.33 19.31a12.94 12.94 0 0 1 -9.17 3.93 14.49 14.49 0 0 1 -10-4.37c-3.77-3.6-7.48-7.35-11.08-11q-2.73-2.78-5.49-5.52v7.33 65.59c0 13.08 5.84 18.95 18.85 19 7.49.05 15.58.08 24.73.08 9.58 0 19.31 0 28.73-.06h3.52c4 0 9.68.7 12.84 6.71a13.63 13.63 0 0 1 -.17 13.41 13.3 13.3 0 0 1 -11.44 6.37l-9.1.11c-9.04.14-18.31.25-27.44.25z" />
          </g>
        </g>
      </svg>
    );
  }
  