export default function GeneralMasterIcon({
    strokeWidth,
    ...props
  }) {
    return (
        <svg
        id="fi_10235325"
        height={512}
        viewBox="0 0 100 100"
        width={512}
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 2"
        {...props}
        style={{height:11,width:11}}
      >
        <path d="m14.52 20.16a10 10 0 1 0 5.68 18.2l13.14 12.64a14.83 14.83 0 0 0 .13 17.74l-10.15 10a10.07 10.07 0 1 0 2.8 2.85l10.16-10a14.9 14.9 0 0 0 21.42-3.42l13.68 6.89a12.11 12.11 0 0 0 -.57 3.68 12.38 12.38 0 1 0 2.38-7.25l-13.7-6.9a14.7 14.7 0 0 0 -.67-11.34l6-3.94a9.5 9.5 0 1 0 -2.25-3.31l-6 3.94a14.91 14.91 0 0 0 -8.19-4.8l1.18-17a12.61 12.61 0 1 0 -4-.28l-1.19 17a14.82 14.82 0 0 0 -8.32 3.21l-13.05-12.6a9.87 9.87 0 0 0 1.56-5.32 10 10 0 0 0 -10.04-9.99zm68.62 50.25a8.33 8.33 0 1 1 -8.33 8.33 8.34 8.34 0 0 1 8.33-8.33zm-11.76-33.32a5.46 5.46 0 1 1 -5.46 5.45 5.46 5.46 0 0 1 5.46-5.45zm-62.85-6.94a6 6 0 1 1 6 6 6 6 0 0 1 -6-6zm9.22 62.85a6 6 0 1 1 6-6 6 6 0 0 1 -6 6zm36.74-39.31a10.88 10.88 0 0 1 .64 10.92 10.91 10.91 0 0 1 -17.52 2.76 10.92 10.92 0 1 1 16.88-13.68zm-14.62-38.09a8.6 8.6 0 1 1 8.6 8.6 8.61 8.61 0 0 1 -8.6-8.6z" />
      </svg>
    );
  }
  