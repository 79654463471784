export const Status = {
    EXIT_ACTION: "EXIT",
    EXIT_MOVEMENT_STATUS: "IN_TRANSIT",

    CONVEYOR_ACTION: "CONVEYOR",
    CONVEYOR_MOVEMENT_STATUS: "IN_TRANSIT",

    ENTRY_ACTION: "ENTRY",
    ENTRY_MOVEMENT_STATUS: "IN_BUILDING",

    BAGGING_AUTO_PROGRESS: "BAGGING_IN_PROGRESS",
    BAGGING_AUTO_ACTION: "BAGGING_AUTO",
    BAGGING_MOVEMENT_STATUS: "IN_BUILDING",
    BAGGING_COMPLETE: "BAGGING_COMPLETE",

    ERROR_IN_WRONG_BUILDING: "IN_WRONG_BUILDING",
    ERROR_DISPATCH_BUILDING_NOT_FOUND: "DISPATCH_BUILDING_NOT_FOUND",
    ERROR_DISPATCHED_FROM_WRONG_BUILDING: "DISPATCHED_FROM_WRONG_BUILDING",
    ERROR_DIFFERENT_BATCH_BAGGING: "TAG_SCANNED_FOR_A_DIFFERENT_OR_NON_BAGGING_BATCH",
    ERROR_DIFFERENT_BUILDING_BAGGING: "TAG_SCANNED_FOR_A_DIFFERENT_BUILDING_BAGGING_BATCH",
    ERROR_WRONG_MATERIAL_DISPATCHED: "ERROR_WRONG_MATERIAL_DISPATCHED",

    UPDATE_BUILDING_ID: true,
    NOT_UPDATE_BUILDING_ID: false,

    ORDER_INITIATED: "ORDER_INITIATED",
    ORDER_PICKED: "ORDER_PICKED",
    ORDER_PICKING: "ORDER_PICKING",
    ORDER_READY_TO_DISPATCH: "ORDER_READY_TO_DISPATCH",
    ORDER_DISPATCHED: "ORDER_DISPATCHED",
    ORDER_LOADING_IN_VEHICLE: "ORDER_LOADING_IN_VEHICLE",
    ORDER_DELIVERED: "ORDER_DELIVERED",
    ORDER_DISPATCHING_IN_PROGRESS: "ORDER_DISPATCHING_IN_PROGRESS",
    ORDER_LOADING: "ORDER_LOADING",

    inventoryRfidTagCol: "inventoryRfidTag"
};
