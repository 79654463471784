export default function MappingMasterIcon({
    strokeWidth,
    ...props
  }) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        id="fi_4160154"
        data-name="Layer 1"
        viewBox="0 0 512 512"
        width={11}
        height={11}
        {...props}
      >
        <path d="M428.19,134.472H500a12,12,0,0,0,12-12V12A12,12,0,0,0,500,0H428.19a12,12,0,0,0-12,12V55.236h-63.3a12,12,0,0,0-12,12V244H309.522V143.528a12,12,0,0,0-12-12H214.478a12,12,0,0,0-12,12V244H171.11V67.236a12,12,0,0,0-12-12H95.81V12a12,12,0,0,0-12-12H12A12,12,0,0,0,0,12V122.472a12,12,0,0,0,12,12H83.81a12,12,0,0,0,12-12V79.236h51.3V244H95.81V200.764a12,12,0,0,0-12-12H12a12,12,0,0,0-12,12V311.236a12,12,0,0,0,12,12H83.81a12,12,0,0,0,12-12V268h51.3V432.764H95.81V389.528a12,12,0,0,0-12-12H12a12,12,0,0,0-12,12V500a12,12,0,0,0,12,12H83.81a12,12,0,0,0,12-12V456.764h63.3a12,12,0,0,0,12-12V268h31.368V368.472a12,12,0,0,0,12,12h83.044a12,12,0,0,0,12-12V268H340.89V444.764a12,12,0,0,0,12,12h63.3V500a12,12,0,0,0,12,12H500a12,12,0,0,0,12-12V389.528a12,12,0,0,0-12-12H428.19a12,12,0,0,0-12,12v43.236h-51.3V268h51.3v43.236a12,12,0,0,0,12,12H500a12,12,0,0,0,12-12V200.764a12,12,0,0,0-12-12H428.19a12,12,0,0,0-12,12V244h-51.3V79.236h51.3v43.236A12,12,0,0,0,428.19,134.472ZM440.19,24H488v86.472H440.19ZM71.81,110.472H24V24H71.81Zm0,188.764H24V212.764H71.81ZM71.81,488H24V401.528H71.81ZM285.522,356.472H226.478V155.528h59.044ZM440.19,401.528H488V488H440.19Zm0-188.764H488v86.472H440.19Z" />
      </svg>
    );
  }
  